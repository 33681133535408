.privacy_container {
  margin: 0 auto;
  width: 80%;
  padding-top: 20px;
  text-align: justify;
  max-width: 984px;

  &__title {
    @include font-family('montserrat-semibold');

    padding: 0;
    text-align: center;
  }

  &__content {
    background-color: rgba(0, 0, 70, 0.4);
    padding: 50px 50px;
    border-radius: 20px;
    -webkit-box-shadow: 1px 4px 13px 2px #000;
    box-shadow: 1px 4px 13px 2px #000;
  }
}
