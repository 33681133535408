.swiper-slide {
  padding-right: 5px;
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff;
  top: 0;
  height: 100%;
  margin-top: 0;
  z-index: 2;
  background-color: rgba($color: $black, $alpha: 0.3);
  width: 4%;
  opacity: 0;

  @media screen and (min-width: 1500px) {
    width: 60px;
  }

  &::after {
    font-size: 1.3rem;

    @media screen and (min-width: 768px) {
      font-size: 1.7rem;
    }

    @media screen and (min-width: 992px) {
      font-size: 2rem;
    }

    @media screen and (min-width: 1500px) {
      font-size: 2.8rem;
    }
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper {
  position: relative;
  padding-right: 4%;
  padding-left: 4%;

  @media screen and (min-width: 1500px) {
    padding-right: 60px;
    padding-left: 60px;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      background-color: rgba($color: $black, $alpha: 0.7);
      opacity: 1;
    }
  }
}

.carousel-asset {
  &-item__background-opacity {
    position: absolute;
    background-color: rgba($color: $black, $alpha: 0.3);
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  &__title {
    &--web {
      @include font-family('montserrat-semibold');

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      letter-spacing: 0;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      text-shadow: 0 100px 210px rgba(0, 0, 0, 0.23), 0 50.0488px 105.103px rgba(0, 0, 0, 0.174877), 0 30.1471px 63.3089px rgba(0, 0, 0, 0.149865), 0 19.3198px 40.5716px rgba(0, 0, 0, 0.131306), 0 12.5216px 26.2953px rgba(0, 0, 0, 0.115), 0 7.88218px 16.5526px rgba(0, 0, 0, 0.0986942), 0 4.5288px 9.51048px rgba(0, 0, 0, 0.0801346), 0 1.99324px 4.18581px rgba(0, 0, 0, 0.0551235);
      line-height: 1.4rem;
      font-size: 1.3rem;
    }

    &--mobile {
      @include font-family('montserrat-semibold');

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      letter-spacing: 0;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      text-shadow: 0 100px 210px rgba(0, 0, 0, 0.23), 0 50.0488px 105.103px rgba(0, 0, 0, 0.174877), 0 30.1471px 63.3089px rgba(0, 0, 0, 0.149865), 0 19.3198px 40.5716px rgba(0, 0, 0, 0.131306), 0 12.5216px 26.2953px rgba(0, 0, 0, 0.115), 0 7.88218px 16.5526px rgba(0, 0, 0, 0.0986942), 0 4.5288px 9.51048px rgba(0, 0, 0, 0.0801346), 0 1.99324px 4.18581px rgba(0, 0, 0, 0.0551235);
      line-height: 1.4rem;
      font-size: 1.3rem;
    }
  }

  &-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    &__img {
      -o-object-fit: cover;
      object-fit: cover;
      opacity: 1;
      top: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      left: 0;
      -webkit-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
      -webkit-box-shadow: -5px 22px 29px rgba(0, 0, 0, 0.06), 0 11.0107px 14.5142px rgba(0, 0, 0, 0.144463), 0 6.63236px 8.74265px rgba(0, 0, 0, 0.123802), 0 4.25036px 5.60275px rgba(0, 0, 0, 0.10847), 0 2.75474px 3.63125px rgba(0, 0, 0, 0.095), 0 1.73408px 2.28583px rgba(0, 0, 0, 0.08153), 0 0.996336px 1.31335px rgba(0, 0, 0, 0.0661981), 0 0.438513px 0.57804px rgba(0, 0, 0, 0.0455368);
      box-shadow: -5px 22px 29px rgba(0, 0, 0, 0.06), 0 11.0107px 14.5142px rgba(0, 0, 0, 0.144463), 0 6.63236px 8.74265px rgba(0, 0, 0, 0.123802), 0 4.25036px 5.60275px rgba(0, 0, 0, 0.10847), 0 2.75474px 3.63125px rgba(0, 0, 0, 0.095), 0 1.73408px 2.28583px rgba(0, 0, 0, 0.08153), 0 0.996336px 1.31335px rgba(0, 0, 0, 0.0661981), 0 0.438513px 0.57804px rgba(0, 0, 0, 0.0455368);
    }

    &__img-box {
      position: relative;
      width: 100%;
      height: 0;
      background-position: center;
      background-size: cover;
      padding: 28% 0;
    }

    &__description {
      color: $white;
      font-size: 0.95rem;
      padding: 0 10px;
      width: 100%;
      letter-spacing: 0;
      font-weight: 400;
      text-shadow: -2px -2px 2px rgb(0, 0, 0);
      z-index: 2;
    }
  }
}
