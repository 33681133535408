/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: defaults,last 4 version,not ie <= 11,not ie_mob <= 11,not op_mini all
*/

/* Import Bootstrap and its default variables */

$color_primary: #000041;
$color_primary_active: #1dd7ff;
$color_gray: #454545;
$navbar-dark-active-color: $color_primary_active;
$navbar-dark-color: #fff;
$red: #e31d26;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~video.js/dist/video-js.min.css';
@import '~swiper/swiper-bundle.min.css';

/* locals */
@import './fonts.scss';
@import './icon-font.scss';
@import './mixin.scss';
@import 'header-footer.scss';
@import 'hero.scss';
@import 'filter-search.scss';
@import 'multimedia.scss';
@import './multimedia-card.scss';
@import './page.scss';
@import './contact-form.scss';
@import './comment.scss';
@import './player.scss';
@import './carousel.scss';
@import './preloader.scss';
@import './skeletonSlider.scss';
@import './survey-form.scss';
@import './privacy.scss';
@import './divider.scss';
@import './asset-modal.scss';
@import './player-layout.scss';

body {
  @include font-family('montserrat');

  min-width: 375px; /* Suppose you want minimum width of 375px */
  width: auto !important; /* Firefox will set width as auto */
  width: 375px; /* As IE6 ignores !important it will set width as 375px; */
  background: rgb(0, 0, 134);
  background:
    -o-radial-gradient(
      4.55% -4.17%,
      circle,
      rgba(0, 0, 134, 1) 0%,
      rgba(0, 0, 65, 1) 35%
    );
  background:
    radial-gradient(
      circle at 4.55% -4.17%,
      rgba(0, 0, 134, 1) 0%,
      rgba(0, 0, 65, 1) 35%
    );
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: $white;

  /**
   * @todo This class must be removed when the following issue is resolved.
   * @see {@link https://github.com/reactstrap/reactstrap/issues/2411}
   */
  &.modal-open {
    overflow: hidden;
  }
}

html,
body,
#root {
  height: 100%;
}

.popover {
  border-radius: 0.25rem;
  border-color: $white;

  .popover-body {
    background: $color_primary;
    color: $white;
    border-radius: 0.25rem;
  }
}

#root {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  & > div {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
}

.layout {
  &__body {
    position: relative;
  }

  &__background {
    position: absolute;
    top: 0;
    -o-object-fit: cover;
    object-fit: cover;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &--absolute {
    .layout {
      &__header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: visible;
        -webkit-transition: all 4.7s ease;
        -o-transition: all 4.7s ease;
        transition: all 4.7s ease;
        z-index: 15;

        &--inactive {
          -webkit-transition: all 0.1s ease;
          -o-transition: all 0.1s ease;
          transition: all 0.1s ease;
          opacity: 0.75;
        }

        &::before {
          position: absolute;
          content: ' ';
          left: 0;
          z-index: 0;
          height: 100px;
          width: 100%;
          top: 0;
          background: rgb(38, 33, 97);
          background:
            -webkit-gradient(
              linear,
              left top,
              left bottom,
              color-stop(10%, rgba($color_primary, 0.7)),
              to(rgba($color_primary, 0))
            );
          background:
            -o-linear-gradient(
              top,
              rgba($color_primary, 0.7) 10%,
              rgba($color_primary, 0)
            );
          background:
            linear-gradient(
              to bottom,
              rgba($color_primary, 0.7) 10%,
              rgba($color_primary, 0)
            );
        }
      }

      &__body {
        position: absolute;
        overflow: hidden;
        background-color: $black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    &:hover {
      .layout__header {
        opacity: 0.75;
        z-index: 15;
        -webkit-transition: all 0.1s ease;
        -o-transition: all 0.1s ease;
        transition: all 0.1s ease;
      }
    }
  }
}

.btn {
  background-color: transparent;
  color: $white;
  -webkit-transition: background 0.2s linear, color 0.2s linear;
  -o-transition: background 0.2s linear, color 0.2s linear;
  transition: background 0.2s linear, color 0.2s linear;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  &:active,
  &:focus,
  &:hover {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: $white !important;
    background: $red !important;
    border-color: $red !important;
  }

  &--light {
    &:active,
    &:focus,
    &:hover {
      color: $black !important;
      background: rgba($white, 0.5) !important;
      border-color: $white !important;
    }
  }

  &-dark {
    border-color: $white !important;
  }

  &-dark.disabled,
  &-dark:disabled,
  &--dark:focus,
  &--dark:hover {
    color: $white !important;
    background: rgba(#000086, 0.5) !important;
    border-color: transparent !important;
  }
}

.container_inner {
  padding-right: 4%;
  padding-left: 4%;

  @media screen and (min-width: 1500px) {
    padding-right: 60px;
    padding-left: 60px;
  }
}

.mr-container {
  margin-right: 4%;

  @media screen and (min-width: 1500px) {
    margin-right: 60px;
  }
}

.outline-0 {
  outline: 0;
}

.container-multimedia {
  &__title {
    font-size: 1.4rem;
  }
}

.list__box {
  margin: 0;
  list-style-type: none;
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

.list__item {
  width: 100%;
  margin-bottom: 2rem;

  @media screen and (min-width: 500px) {
    width: 48%;

    &:nth-last-of-type(-n + 2) {
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.form-data {
  &__label {
    @include font-family('montserrat');

    letter-spacing: -1px;
    line-height: 1;
    color: $white;
    text-align: center;
    margin: 0;
  }

  &__title {
    @include font-family('montserrat-medium');

    font-size: 1.5rem;
    letter-spacing: -1px;
    line-height: 1.8rem;
    color: $white;
    text-align: center;
  }

  &__button {
    height: 50px;
  }
}

.input_form {
  position: relative;

  label {
    display: -webkit-box;
    display: flex;
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .place-label {
    position: absolute;
    left: 5px;
    color: $white;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition:
      font 0.1s ease,
      top 0.1s ease,
      transform 0.1s ease,
      -webkit-transform 0.1s ease;
  }

  input {
    @include font-family('montserrat-medium');

    background-color: transparent;
    border: 0;
    height: 50px;
    line-height: 50px;
    padding: 16px 20px 0 5px;
    border-radius: 0.25rem;
    letter-spacing: -1px;
    color: $white !important;

    &:focus ~ .place-label,
    &.has-text ~ .place-label {
      top: 7px;
      font-size: 11px;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .input--login {
    border-bottom: 1px solid $white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  background: inherit;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.button-filled,
.button-empty {
  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.button-empty {
  width: 130px;
  line-height: 1.3;
}

.body-main {
  z-index: 1;
}

svg {
  width: 100%;
}

.title_size {
  @include font-family('montserrat-bold');

  font-size: 1.2rem;

  @media screen and (min-width: 768px) {
    font-size: 2.6vw;
  }

  @media screen and (min-width: 1024px) {
    font-size: 1.8vw;
  }

  @media screen and (min-width: 1500px) {
    font-size: 1.5vw;
  }
}

.grid-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &__item {
    width: 50%;
    padding: 0 2px;
    margin-bottom: 40px;

    @media screen and (min-width: 500px) {
      width: 33.33%;
    }

    @media screen and (min-width: 800px) {
      width: 25%;
    }

    @media screen and (min-width: 1100px) {
      width: 20%;
    }

    @media screen and (min-width: 1400px) {
      width: 16.66666667%;
    }
  }

  &--search {
    .grid-list__item {
      @media screen and (min-width: 1024px) {
        width: 33.33%;
      }

      @media screen and (min-width: 1100px) {
        width: 33.33%;
      }

      @media screen and (min-width: 1400px) {
        width: 25%;
      }
    }
  }
}

.page {
  position: relative;
  z-index: 3;
  margin-bottom: 60px;

  @media screen and (min-width: 1400px) {
    min-height: 200px;
  }
}

.ss-detail-logo {
  width: 30px;
}

.color-icon {
  svg path {
    fill: #fff !important;
  }
}

.font-size {
  &--tiny {
    font-size: 0.8rem;
  }

  &--small {
    font-size: 0.85rem;
  }

  &--regular {
    font-size: 0.9rem;
  }
}

.line-height {
  line-height: 1;

  &--0 {
    line-height: 0;
  }
}

.gout {
  &::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 56.25vh;
    border-radius: 50%;
    background: #000086;
    background: linear-gradient(213deg, #000086 0%, #000041 35%);
    bottom: 0;
    left: 0;
    transform: translate(-40%, 92%);

    @media screen and (min-width: 768px) {
      transform: translate(-50%, 90%);
    }

    @media screen and (min-width: 1200px) {
      transform: translate(-50%, 87%);
    }

    @media screen and (min-width: 1500px) {
      transform: translate(-60%, 83%);
    }
  }
}

.button-width {
  width: 130px;
}

.user-notification {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 3;

  &--resolution {
    @media screen and (min-width: 375px) {
      display: none;
    }
  }
}

.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;

  .rcs-inner-container {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-right: 15px;
  }

  .rcs-custom-scrollbar {
    position: absolute;
    height: 100%;
    width: 6px;
    right: 3px;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.4s ease-out;
    padding: 6px 0;
    box-sizing: border-box;
    will-change: opacity;
    pointer-events: none;
  }

  .rcs-scroll-handle-dragged {
    .rcs-custom-scrollbar {
      opacity: 1;
    }
  }

  .rcs-custom-scroll-handle {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .rcs-inner-handle {
    height: calc(100% - 12px);
    margin-top: 6px;
    background-color: $navbar-dark-color;
    border-radius: 3px;
  }

  .rcs-outer-container {
    overflow: hidden;

    .rcs-positioning {
      position: relative;
    }

    ::-webkit-scrollbar {
      display: none;
    }

    &:hover {
      .rcs-custom-scrollbar {
        opacity: 1;
        transition-duration: 0.2s;
      }
    }
  }
}

.modal-backdrop {
  background: #040031;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.modal-lg {
  max-width: 860px;
  padding-left: 4%;
  padding-right: 4%;
}

.tab-ui {
  &__list {
    border: none;
    margin-bottom: 1rem !important;
  }

  &__list-item {
    padding-right: 1rem;
    border-right: 1px solid #fff;
    margin-right: 1rem;
  }

  &__list-link {
    color: #fff;
    text-align: center;
    font-size: 1.25rem;
    letter-spacing: 1px;
    line-height: 1;
    filter: drop-shadow(0 2px 7.5px #000);
    border: 0 !important;
    border-radius: 0 !important;
    padding: 12px 0;
    cursor: pointer;

    &:hover,
    &:focus {
      color: #fff !important;
      outline: none;
    }

    &.active {
      color: $color_primary_active !important;
      background-color: transparent !important;
    }
  }
}

.container-icon {
  background: #1b1659;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;

  &--list {
    width: 23px;
    height: 23px;
  }
}

.item-card {
  border-radius: 3px;
  filter: drop-shadow(0 2px 4px rgba(18, 17, 18, 0.4));
  background-color: #1b1659;
}

.letter-spacing {
  &__-1 {
    letter-spacing: -1px;
  }
}

canvas.pdf {
  height: 1px;
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.navbar-dark {
  .navbar-nav {
    .nav-link {
      color: $navbar-dark-color;

    }

    .show > .nav-link,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }
}
