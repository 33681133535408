.contact__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  &-error,
  &-success {
    width: 100%;
    text-align: center;
    padding: 6px 12px;
    border-radius: 5px;
  }

  &-error {
    border: 1px solid $red;
    background-color: $red;
  }

  &-success {
    background-color: green;
  }
}

.input-group {
  margin-bottom: 1.2rem;

  &__input {
    padding: 0.5rem;
    width: 100%;
    border-radius: 0.5rem;
  }

  textarea.input-group__input {
    height: 90px;
  }
}
