.divider {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__border {
    border: 1px solid $navbar-dark-color;
    border-radius: 25%;
    width: 100%;
  }

  &__content {
    position: absolute;
  }

  &__content-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #1b1659, $alpha: 0.7) !important;
    border: 1px solid #fff;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    z-index: 8;

    i {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
