.comment {
  &__row {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__username,
  &__title {
    @include font-family('montserrat-bold');
  }

  &__picture {
    width: 40px;
  }

  &__text {
    text-align: justify;
    line-height: 1.3;
    word-break: break-all;
  }

  &__star {
    font-size: 35px;
  }

  &__default-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $color_gray;
  }

  &__widgets {
    height: 200px;
    max-height: 300px;
  }
}
