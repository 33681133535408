.player-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    width: calc(100% - 10%);
    max-width: 1110px;
    margin: 1rem auto;
    padding: 1rem 0;
  }

  &__footer {
    margin-top: auto;
  }

  &__header,
  &__footer {
    background: #020040;
  }

  &__overlay {
    position: fixed;
    background: rgba($color: #000, $alpha: 0.6);
    z-index: 8;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &__next-asset {
    &__image {
      width: 230px;

      @media screen and (min-width: 1024px) {
        width: 270px;
      }
    }
  }

  &__next-element {
    max-width: 500px;
  }

  &--pdf {
    overflow: scroll;
    overflow-x: hidden;
  }
}

.react-pdf {
  &__document {
    max-width: 100%;
    position: relative;
  }

  &__Page {
    &__svg {
      width: auto !important;
      height: auto !important;
    }
  }
}
