.asset-modal {
  .modal-content {
    border-radius: 6px;
    filter: drop-shadow(0 2px 4px rgba(18, 17, 18, 0.4));
    background-color: #020040;
    color: $white;
    min-height: 80vh;
  }

  .modal-border__header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .button_player {
    position: absolute;
    top: 50%;
    z-index: 9;
    right: 20px;
    transform: translateY(-50%);
    background-color: #020040 !important;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 9px;
    justify-content: center;
    align-items: center;

    &--play {
      font-size: 18px;

      i {
        transform: translateX(2px);
      }
    }
  }

  &__header {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    z-index: 99;
  }

  &__close {
    width: 39px;
    height: 39px;
    border-radius: 20px;
    background-color: #020040 !important;
    color: #fff;
    border: 1px solid #020040;
    padding: 0;
    display: flex;
    border-color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 33px;
  }
}

.asset-overlaid {
  &__title {
    position: absolute;
    bottom: 12px;
    right: 0;
    left: 0;
    width: 100%;
  }

  &__icon-type {
    position: absolute;
    bottom: 12px;
  }

  &__progress-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.progress {
  &-content {
    width: 100%;
  }

  &-bar {
    height: 2px;
    background-color: #f00;
  }
}

.asset-list {
  &__item {
    background: transparent;
    border: 2px solid #fff;
    color: #fefefe;
    padding: 2.5rem 20px;

    &:hover {
      background: #1b1659;
    }

    @media screen and (min-width: 576px) {
      padding: 1.5rem 20px;
    }

    @media screen and (min-width: 768px) {
      padding: 1rem 20px;
    }

    @media screen and (min-width: 992px) {
      padding: 12px 20px;
    }
  }

  &__image-wrapper {
    flex: 0 0 18%;
    min-width: 130px;
  }

  &__title,
  &__position {
    font-size: 17px;
  }

  &__title-wrapper {
    padding: 0 1rem 0.5rem 0;

    @media screen and (min-width: 768px) {
      padding: 1rem 1rem 0.5rem 0;
    }
  }

  &__description-wrapper {
    font-size: 14px;
    padding: 0;

    @media screen and (min-width: 768px) {
      padding: 0 1rem 1rem 0;
    }
  }

  &__container {
    background: #1b1659;
    padding: 0.25rem;
  }
}
