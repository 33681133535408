.filter-search {
  border: 1px solid $white;
  border-radius: 0.25rem;

  &__title {
    border-bottom: 1px solid $color_primary;
    border-top: 1px solid $color_primary;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__item {
    border: 0;
    background-color: transparent;
    padding: 0.75rem 0.75rem;
  }

  &__item--children {
    padding: 0.5rem 0.5rem;
  }

  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    @media screen and (min-width: 992px) {
      display: block;
    }

    .form-control,
    .filter-search__button {
      background-color: transparent;
      border-radius: 0;
    }

    .filter-search__button {
      outline: 0 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0;
      padding: 0.75rem 1rem;

      &--menu {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:hover {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        background: $white;
        color: $black;
      }

      &:active {
        color: $color_primary_active !important;
      }

      &:focus,
      &:active {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        background: $white !important;
        border-color: $white !important;
        color: $color_primary !important;
      }
    }

    .form-control {
      height: auto !important;
      padding: 0.75rem;
      color: $white;
      border-top-left-radius: 0;
      border-bottom: 0;
      border-top: 0;
      border-width: 1px;

      @media screen and (min-width: 992px) {
        border-top-left-radius: 0.25rem;
        border-left: 0;
      }

      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  &__content {
    border-top: 1px solid $white;

    @media screen and (min-width: 992px) {
      .collapse {
        display: block !important;
      }
    }
  }
}
