.pulsate {
  -webkit-animation-duration: 3.6s;
  animation-duration: 3.6s;
  -webkit-animation-name: pulsate-animation;
  animation-name: pulsate-animation;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in;
}

@-webkit-keyframes pulsate-animation {
  0% {
    background-color: $color_primary;
  }

  25% {
    background-color: #4138a6;
  }

  50% {
    background-color: $color_primary;
  }

  100% {
    background-color: $color_primary;
  }
}

@keyframes pulsate-animation {
  0% {
    background-color: $color_primary;
  }

  25% {
    background-color: #4138a6;
  }

  50% {
    background-color: $color_primary;
  }

  100% {
    background-color: $color_primary;
  }
}

.skeleton_slider {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;

  & > div {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    position: relative;
    display: block;

    .pulsate {
      padding-bottom: 56.25%;
    }

    @media screen and (min-width: 500px) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33%;
      flex: 0 0 33.33%;
    }

    @media screen and (min-width: 800px) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
    }

    @media screen and (min-width: 1100px) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
    }

    @media screen and (min-width: 1400px) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
      flex: 0 0 16.66666667%;
    }

    &:nth-child(1) {
      .pulsate {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
    }

    &:nth-child(2) {
      .pulsate {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }
    }

    &:nth-child(3) {
      .pulsate {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
      }
    }

    &:nth-child(4) {
      .pulsate {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
      }
    }

    &:nth-child(5) {
      .pulsate {
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
      }
    }

    &:nth-child(6) {
      .pulsate {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }
    }

    &:nth-child(7) {
      .pulsate {
        -webkit-animation-delay: 1.2s;
        animation-delay: 1.2s;
      }
    }
  }
}
