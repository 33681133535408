.multimedia {
  &__content {
    text-shadow: 2px 2px 1px $black;
  }

  &__title {
    @include font-family('montserrat-bold');

    font-size: 4.7vw;
    text-shadow: 0 2px 1.5px $color_primary;

    @media screen and (min-width: 768px) {
      letter-spacing: -3px;
      line-height: 1;
    }
  }

  &__button {
    @include font-family('montserrat-medium');
  }

  &__description {
    @include font-family('montserrat-medium');

    margin: 0;
    display: none;
    letter-spacing: 0;
    line-height: 1;
    text-shadow: 0 2px 5px $color_primary;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }
}
