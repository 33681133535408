.survey {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 6rem;

  &__title {
    text-align: center;
  }

  &__comment-section {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  &__submit-btn {
    background-color: #436dc1;
    align-self: flex-end;
    border-radius: 4.64px;
    width: 100%;
    max-width: 146px;
    max-height: 42px;
    color: $white;
    font-weight: bold;
  }
}

.rate {
  &__text {
    @media screen and (min-width: 1200px) {
      margin: 0;
    }
  }

  &__options-control {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;

    @media screen and (min-width: 1200px) {
      width: 40%;
    }
  }

  &__fields {
    @media screen and (min-width: 1200px) {
      -webkit-box-align: center;
      align-items: center;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__option {
    margin-bottom: 0;
    position: relative;
    width: 41px;

    input[type="radio"] {
      opacity: 0;
      height: 0;
      width: 0;
    }

    i {
      cursor: pointer;
      font-size: 2.5rem;
    }

    input[type="radio"]:hover ~ i,
    input[type="radio"]:active ~ i,
    input[type="radio"]:checked ~ i {
      color: #ffc100;
    }
  }
}

#qualification_answer_veryPoor,
#qualification_answer_poor,
#qualification_answer_meh,
#qualification_answer_good,
#qualification_answer_excellent {
  ~ i::before {
    position: relative;
    background: linear-gradient(to top, #ffc100, #ffc100 50%, #fff 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100% 200%;
    background-position: top;
    transition: all 0.4s ease;
  }

  &:hover ~ i::before,
  &:checked ~ i::before {
    background-position: 0 100%;
  }
}

#qualification_answer_veryPoor {
  &:hover ~ i::before,
  &:checked ~ i::before {
    content: "\e900";
  }
}

#qualification_answer_poor {
  &:hover ~ i::before,
  &:checked ~ i::before {
    content: "\e901";
  }
}

#qualification_answer_meh {
  &:hover ~ i::before,
  &:checked ~ i::before {
    content: "\e902";
  }
}

#qualification_answer_good {
  &:hover ~ i::before,
  &:checked ~ i::before {
    content: "\e904";
  }
}

#qualification_answer_excellent {
  &:hover ~ i::before,
  &:checked ~ i::before {
    content: "\e906";
  }
}
