.hero {
  position: relative;
  width: 100%;

  &::before {
    position: absolute;
    content: ' ';
    left: 0;
    z-index: 1;
    height: 100px;
    width: 100%;
    top: 0;
    background: rgb(38, 33, 97);
    background:
      -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(10%, rgba($color_primary, 0.7)),
        to(rgba($color_primary, 0))
      );
    background:
      -o-linear-gradient(
        top,
        rgba($color_primary, 0.7) 10%,
        rgba($color_primary, 0)
      );
    background:
      linear-gradient(
        to bottom,
        rgba($color_primary, 0.7) 10%,
        rgba($color_primary, 0)
      );
  }

  &__multimedia {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &::after {
      background-size: 100% 100%;
      background-position: 0 top;
      background-repeat: repeat-x;
      background-color: transparent;
      width: 100%;
      height: 14.7vw;
      top: auto;
      bottom: -1px;
      position: absolute;
      content: ' ';
      left: 0;
      z-index: 1;
      opacity: 1;
      background-image:
        -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(0, rgba($color_primary, 0)),
          color-stop(15%, rgba($color_primary, 0.15)),
          color-stop(29%, rgba($color_primary, 0.35)),
          color-stop(44%, rgba($color_primary, 0.58)),
          color-stop(68%, rgba($color_primary, 0.8)),
          to($color_primary)
        );
      background-image:
        -o-linear-gradient(
          top,
          rgba($color_primary, 0) 0,
          rgba($color_primary, 0.15) 15%,
          rgba($color_primary, 0.35) 29%,
          rgba($color_primary, 0.58) 44%,
          rgba($color_primary, 0.8) 68%,
          $color_primary 100%
        );
      background-image:
        linear-gradient(
          to bottom,
          rgba($color_primary, 0) 0,
          rgba($color_primary, 0.15) 15%,
          rgba($color_primary, 0.35) 29%,
          rgba($color_primary, 0.58) 44%,
          rgba($color_primary, 0.8) 68%,
          $color_primary 100%
        );
    }
  }

  &__media {
    position: relative;
  }

  &__img {
    height: 100%;
    width: 100%;
  }

  &__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    height: 100%;
    z-index: 2;
  }

  &--web {
    .hero__brand {
      width: 30px;
      -o-object-fit: cover;
      object-fit: cover;

      @media screen and (min-width: 768px) {
        width: 70px;
      }

      @media screen and (min-width: 1024px) {
        width: 100px;
      }

      @media screen and (min-width: 1200px) {
        width: 130px;
      }
    }

    .hero__multimedia {
      height: 56.25vw;
    }

    .multimedia__button {
      width: auto;

      @media screen and (min-width: 768px) {
        width: 230px;
      }
    }

    .hero__content {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .hero__content--web {
      margin-bottom: 20px;
      padding-bottom: 38%;
    }

    .hero__content-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  &__wrapper {
    width: 100%;

    @media screen and (min-width: 992px) {
      width: 70%;
    }

    @media screen and (min-width: 1200px) {
      width: 60%;
    }
  }

  &--mobile {
    height: calc(48vh + 80px);
    margin-bottom: 20px;

    @media screen and (min-width: 600px) and (orientation: landscape) {
      height: calc(70vh + 80px);
    }

    @media screen and (orientation: portrait) {
      height: calc(70vw + 80px) !important;
    }

    .hero__brand {
      width: 30px;

      @media screen and (min-width: 768px) {
        width: 70px;
      }
    }

    .hero__img {
      @media screen and (orientation: portrait) {
        object-fit: contain;
      }

      @media screen and (orientation: landscape) {
        object-fit: cover;
      }
    }

    .hero__multimedia {
      &::after {
        height: 20.7vw;
      }
    }

    .hero__button {
      width: 33%;
    }

    .multimedia__title {
      text-align: center;
      font-size: 1.7rem;
    }

    .hero__content {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }

    &::after {
      bottom: 76px;
    }

    .hero__media {
      width: 100%;
      height: 100%;

      &::after {
        background-color: rgba($black, 0.3);
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    .hero__wrapper {
      width: 100%;

      @media screen and (min-width: 992px) {
        width: 100%;
      }

      @media screen and (min-width: 1200px) {
        width: 100%;
      }
    }
  }
}
