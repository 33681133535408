/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 3, 2021 */

@font-face {
  font-family: 'montserrat-thin';
  src:
    url('./../fonts/montserrat-regular-webfont.woff2') format('woff2'),
    url('./../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src:
    url('./../fonts/montserrat-regular-webfont.woff2') format('woff2'),
    url('./../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-medium';
  src:
    url('./../fonts/montserrat-regular-webfont.woff2') format('woff2'),
    url('./../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-semibold';
  src:
    url('./../fonts/montserrat-regular-webfont.woff2') format('woff2'),
    url('./../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-bold';
  src:
    url('./../fonts/montserrat-regular-webfont.woff2') format('woff2'),
    url('./../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
