@font-face {
  font-family: 'icomoon';
  src:
    // url(('./../fonts/icomoon.?85df2a'),
    // url('./../fonts/icomoon.?85df2a#iefix') format('embedded-opentype'),
    url('./../fonts/icomoon.ttf?85df2a') format('truetype'),
    url('./../fonts/icomoon.woff?85df2a') format('woff'),
    url('./../fonts/icomoon.svg?85df2a#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', Arial, Helvetica, sans-serif !important;
  // speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-drop_down::before {
  content: "\e941";
}

.icon-drop_up::before {
  content: "\e946";
}

.icon-globe::before {
  content: "\e947";
}

.icon-star_1::before {
  content: "\e900";
}

.icon-star_2::before {
  content: "\e901";
}

.icon-star_3::before {
  content: "\e902";
}

.icon-star_4::before {
  content: "\e904";
}

.icon-star_5::before {
  content: "\e906";
}

.icon-info::before {
  content: "\e945";
}

.icon-chevron-down-solid::before {
  content: "\e940";
}

.icon-logout::before {
  content: "\e942";
}

.icon-programa::before {
  content: "\e943";
}

.icon-times-solid::before {
  content: "\e944";
}

.icon-loading::before {
  content: "\e93e";
}

.icon-infografia::before {
  content: "\e93f";
}

.icon-favorite_off::before {
  content: "\e91e";
}

.icon-favorite_on::before {
  content: "\e937";
}

.icon-like_off::before {
  content: "\e938";
}

.icon-like_on::before {
  content: "\e939";
}

.icon-go_back_menu::before {
  content: "\e93a";
}

.icon-go_back::before {
  content: "\e93b";
}

.icon-volume_off::before {
  content: "\e93c";
}

.icon-volume_on::before {
  content: "\e93d";
}

.icon-video_completed::before {
  content: "\e903";
}

.icon-videos::before {
  content: "\e905";
}

.icon-show_late::before {
  content: "\e907";
}

.icon-user::before {
  content: "\e908";
}

.icon-trash::before {
  content: "\e909";
}

.icon-sub_menu_2::before {
  content: "\e90a";
}

.icon-sub_menu::before {
  content: "\e90b";
}

.icon-save::before {
  content: "\e90c";
}

.icon-play::before {
  content: "\e90d";
}

.icon-arrow_left::before {
  content: "\e90e";
}

.icon-step-backward::before {
  content: "\e90f";
}

.icon-refresh::before {
  content: "\e910";
}

.icon-remember::before {
  content: "\e911";
}

.icon-settings::before {
  content: "\e912";
}

.icon-pause::before {
  content: "\e913";
}

.icon-no_like::before {
  content: "\e914";
}

.icon-no_available::before {
  content: "\e915";
}

.icon-minimize_video::before {
  content: "\e916";
}

.icon-minimize_screen::before {
  content: "\e917";
}

.icon-like::before {
  content: "\e918";
}

.icon-menu::before {
  content: "\e919";
}

.icon-search_plus::before {
  content: "\e91a";
}

.icon-search_minus::before {
  content: "\e91b";
}

.icon-inicio::before {
  content: "\e91c";
}

.icon-information::before {
  content: "\e91d";
}

.icon-print::before {
  content: "\e91f";
}

.icon-image::before {
  content: "\e920";
}

.icon-filter::before {
  content: "\e921";
}

.icon-favorites::before {
  content: "\e922";
}

.icon-explore::before {
  content: "\e923";
}

.icon-duplicate::before {
  content: "\e924";
}

.icon-available::before {
  content: "\e925";
}

.icon-download::before {
  content: "\e926";
}

.icon-email::before {
  content: "\e927";
}

.icon-password_2::before {
  content: "\e928";
}

.icon-password::before {
  content: "\e929";
}

.icon-share::before {
  content: "\e92a";
}

.icon-comments::before {
  content: "\e92b";
}

.icon-close::before {
  content: "\e92c";
}

.icon-upload::before {
  content: "\e92d";
}

.icon-calendar::before {
  content: "\e92e";
}

.icon-search::before {
  content: "\e92f";
}

.icon-avrir_en_otra_ventana::before {
  content: "\e930";
}

.icon-arrow_right::before {
  content: "\e931";
}

.icon-step-forward::before {
  content: "\e932";
}

.icon-audio::before {
  content: "\e933";
}

.icon-file::before {
  content: "\e934";
}

.icon-plus::before {
  content: "\e935";
}

.icon-attach::before {
  content: "\e936";
}

.standard-icon-height {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;

  &--10 {
    height: 10px;
  }

  &--16 {
    height: 16px;
  }

  &--18 {
    height: 18px;
  }

  &--26 {
    height: 26px;
  }

  &--40 {
    height: 40px;
  }
}

.standard-icon-size {
  &--8 {
    font-size: 8px;
    line-height: 1;
  }

  &--14 {
    font-size: 14px;
    line-height: 1;
  }

  &--16 {
    line-height: 1;
    font-size: 16px;
  }

  &--26 {
    font-size: 26px;
    line-height: 1;
  }

  &--40 {
    font-size: 40px;
    line-height: 1;
  }

  &--20 {
    font-size: 20px;
    line-height: 1;
  }

  &--21 {
    font-size: 21px;
    line-height: 1;
  }
}

.header-icon {
  &__logout {
    font-size: 23px;
  }
}

.hero-content-web {
  &__info {
    font-size: 18px;
  }

  &__favorite {
    font-size: 25px;
  }
}

.hero-content-mobile {
  &__info {
    font-size: 25px;
  }

  &__favorite {
    font-size: 45px;
  }

  &__play {
    font-size: 25px;
  }

  &__qualify {
    font-size: 29px;
  }
}

.detail-page-icon {
  &__share {
    font-size: 29px;
  }

  &__email {
    font-size: 28px;
  }

  &__video {
    font-size: 20px;
  }

  &__pdf {
    svg {
      height: 14px;
      width: 20px;
      vertical-align: top;
    }

    path {
      fill: $white;
      stroke: $white;
    }

    &--big {
      svg {
        height: 17px;
        width: 17px;
      }
    }

    &--small {
      svg {
        height: 13px;
        width: 13px;
      }
    }
  }
}
