.multimedia-card {
  color: $white;
  word-wrap: break-word;
  position: relative;
  line-height: 1.1;

  &__detail {
    margin-top: 1rem;
  }

  &__logo {
    width: 140px;
  }

  &__title {
    @include font-family('montserrat-bold');

    display: block;
    font-size: 1.5rem;
    color: $white;
  }

  &__link {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &__wrapper {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
  }

  &__description {
    word-break: break-word;
    font-size: 0.9rem;
    letter-spacing: 0;
    line-height: 1.3;
  }

  &__play-button {
    position: absolute;
    content: '';
    background-color: rgba($color: $black, $alpha: 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;

    i {
      font-size: 50px;
      color: rgba(255, 255, 255, 0.5);

      @media screen and (min-width: 768px) {
        font-size: 5vw;
      }
    }
  }

  &__image-container {
    @media screen and (min-width: 992px) {
      width: 49%;
      margin-right: 0.5rem;
    }
  }

  &__image {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .multimedia-card__src {
      height: 100%;
      width: 100%;
    }
  }

  &__canal {
    font-size: 0.9em;
  }

  &__canal,
  &__view {
    margin: 0;
  }

  &__action {
    .btn {
      width: 100%;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .multimedia-card__view {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-left: 0.5rem;
    }
  }

  &__general {
    font-size: 0.8em;
  }
}
