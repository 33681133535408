.container-spinner {
  width: 70px;
  height: 70px;
}

.lds-spinner {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.lds-spinner div {
  transform-origin: 1px 30px;
  animation: lds-spinner 1.2s linear infinite;
  display: block;
  position: absolute;
  width: 2px;
  height: 20px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(135deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(225deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(315deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
