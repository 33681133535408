.result-page {
  .multimedia-card {
    @media screen and (min-width: 1200px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    &__image {
      @media screen and (min-width: 1200px) {
        margin-right: 0.5rem;
      }
    }

    &__detail {
      @media screen and (min-width: 1200px) {
        margin-top: 0;
        max-width: 48%;
      }
    }
  }

  .filter-search__content {
    .list-group {
      &:first-child {
        .filter-search__title {
          border-top: 0;
          padding-top: 0;
        }
      }
    }
  }

  .skeleton_slider {
    & > div {
      @media screen and (min-width: 1024px) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
      }

      @media screen and (min-width: 1100px) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
      }

      @media screen and (min-width: 1400px) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
      }
    }
  }
}

.history-page {
  .multimedia-card {
    @media screen and (min-width: 992px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    &__image {
      @media screen and (min-width: 992px) {
        margin-right: 0.5rem;
      }
    }

    &__detail {
      @media screen and (min-width: 992px) {
        margin-top: 0;
        max-width: 48%;
      }
    }
  }
}

.condition-page {
  .card {
    max-width: 1024px;
  }
}

.contact-page {
  .card {
    max-width: 700px;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;

  .input-group {
    &__input {
      background-color: transparent;
      border: 2px solid $white;
      color: $white;

      &::-webkit-input-placeholder {
        color: $white;
      }

      &::-moz-placeholder {
        color: $white;
      }

      &::-ms-input-placeholder {
        color: $white;
      }

      &:-ms-input-placeholder {
        color: $white;
      }

      &::placeholder {
        color: $white;
      }

      &:focus {
        border-color: $color_primary_active;
      }

      option {
        background-color: $black;
        color: $white;
        padding: 0.5rem;
      }
    }
  }
}

.contact-page,
.condition-page {
  .card {
    margin: auto;
    background-color: transparent;

    @media screen and (min-width: 992px) {
      width: 100%;
    }
  }

  .card-body {
    border-radius: 0.25rem;
    border: 1px solid $white;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 2rem;
  }

  .card-text {
    @include font-family('montserrat-medium');

    font-size: 0.9rem;
  }

  .card-header {
    @include font-family('montserrat-bold');

    background-color: rgba($color: $white, $alpha: 0.8);
    color: $black;
    font-size: 1.3rem;
    padding: 12px 20px;
  }
}

.detail-page {
  &__suggest {
    margin-bottom: 2rem;

    @media screen and (max-width: 991px) {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }

    .multimedia-card {
      width: 48%;
      margin-bottom: 2rem;
      font-size: 0.8em;

      &__information {
        margin-bottom: 0;
      }

      &__canal {
        margin-bottom: 0.25rem;
      }

      &__view {
        margin-left: 0;
      }

      @media screen and (min-width: 992px) {
        &:last-child {
          margin-bottom: 0;
        }
      }

      &__title {
        font-size: 0.9rem;
      }

      @media screen and (min-width: 1024px) {
        font-size: 0.85em;
      }

      @media screen and (min-width: 1200px) {
        font-size: 0.9em;
      }
    }

    @media screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  &__suggest-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media screen and (min-width: 992px) {
      display: block;
    }

    .multimedia-card {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      &__logo {
        display: none;
      }

      &__detail {
        margin-top: 0.3rem;
        padding-left: 0.5rem;
        width: 60%;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }

      &__image-container {
        width: 40%;
        margin-right: 0;
      }

      @media screen and (max-width: 991px) {
        margin-bottom: 1rem;

        &__information {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }

        &__detail {
          margin-top: 0;
          width: 60%;
          padding-left: 2%;
        }

        &__title {
          font-size: 0.8rem;
        }

        &__canal {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .detail-page__content,
  .detail-page__comment {
    margin-bottom: 2rem;
  }

  .detail-page__content {
    .multimedia-card {
      &__image-container {
        @media screen and (min-width: 992px) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  &__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .detail-page__content,
    .detail-page__suggest,
    .detail-page__comment {
      width: 100%;
    }

    @media screen and (min-width: 992px) {
      .detail-page__content,
      .detail-page__comment {
        width: 60%;
        padding-right: 2%;
      }

      .detail-page__suggest {
        width: 40%;
      }
    }
  }
}

.login-page {
  position: relative;
  overflow: hidden;
  height: 100%;

  &__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    position: relative;
    z-index: 1;
    height: 100%;
  }

  &__imagotipo {
    max-width: 200px;
    margin: 0 auto;
    display: block;
    z-index: 1;

    @media screen and (min-width: 1200px) {
      max-width: 250px;
    }
  }

  &__wrapper {
    max-width: 637px;
    width: 100%;
    margin: auto;

    &--form {
      max-width: 317px;
    }
  }

  &__terms-condition {
    text-align: center;
    margin: 0 auto;

    &-check {
      margin-right: 10px;
    }
  }

  &__header {
    width: 100%;

    &__logo {
      width: 50px;
      height: 100%;
      max-height: 120px;
      margin-left: 2%;
      margin-top: 15px;

      @media screen and (min-width: 768px) {
        width: 70px;
        max-height: 140px;
      }

      @media screen and (min-width: 1024px) {
        width: 100px;
        max-height: 170px;
      }

      @media screen and (min-width: 1200px) {
        width: 130px;
        max-height: 200px;
      }
    }
  }

  &__gif {
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: contain;

    &-background {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.result-page,
.history-page,
.contact-page,
.condition-page,
.detail-page {
  margin-top: 65px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }

  padding: 1rem 0;

  @media screen and (min-width: 992px) {
    padding: 2.5rem 0;
  }
}

.player-page {
  overflow: hidden;

  .video-js {
    background-color: $black;

    &::after {
      display: none;
    }
  }

  .vjs-poster {
    background-size: contain !important;
  }

  &.show-poster {
    .vjs-poster {
      display: block !important;
    }
  }
}
