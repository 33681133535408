.video-js {
  background-color: $color_primary;

  &::after {
    background-color: rgba($black, 0.3);
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  video {
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (min-width: 375px) {
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    height: 3em;
    width: 3em;
    margin-top: -1.5em;
    margin-left: -1.5em;
    border-radius: 50%;
    border: none;
    background-color: rgba($color: $black, $alpha: 0.7);

    .vjs-icon-placeholder {
      line-height: 1.4;
      font-size: 60px;
    }
  }

  .vjs-poster {
    background-color: transparent;
    background-size: cover;
  }

  &:hover {
    .vjs-big-play-button {
      background-color: rgba($color: $black, $alpha: 0.7);

      .vjs-icon-placeholder {
        color: $primary;
      }
    }
  }

  .vjs-control-bar {
    height: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: rgba($color: $black, $alpha: 0.7);
  }

  /* Regla css para todos los hijos de ".vjs-control-bar" */
  .vjs-control {
    height: 30px;
  }

  /* Regla css para el boton de play y pause */
  .vjs-play-control,
  .vjs-paused {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .vjs-time-control {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  /* Regla css para la barra de progreso del player */
  .vjs-progress-control {
    height: 20px;
    -webkit-box-ordinal-group: -1;
    -ms-flex-order: -2;
    order: -2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;

    .vjs-play-progress {
      background-color: $primary;
    }
  }

  /* Reglas css para el contenedor de volumen */
  .vjs-volume-panel {
    margin-right: auto;
  }

  /*
  .vjs-subs-caps-button {
    .vjs-menu {
      margin-bottom: 10px;

      .vjs-subtitles-menu-item {
        text-transform: capitalize;
      }

      .vjs-menu-content {
        bottom: 0;
      }
    }
  }
  */

  .vjs-tech,
  .vjs-progress-control,
  .vjs-control-bar {
    button:focus {
      outline: none;
    }
  }
}

.background-9-16 {
  width: 100%;
  padding-top: 56.3925%;
  overflow: hidden;
  position: relative;

  &__asset_display {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__after-shadow {
    &::after {
      background-size: 100% 100%;
      background-position: 0 top;
      background-repeat: repeat-x;
      background-color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: -1px;
      position: absolute;
      content: ' ';
      left: 0;
      z-index: 1;
      opacity: 1;
      background-image:
        linear-gradient(
          rgba(0, 0, 0, 0) 34.84%,
          rgba(0, 0, 29, 0.37) 71.87%,
          rgba(0, 0, 43, 0.58) 78.73%,
          rgba(0, 0, 48, 0.79) 86.08%,
          #020040 97%
        );
    }
  }
}

.vjs-text-track-display {
  z-index: 9;
}

.layout__body {
  .video-js {
    overflow: hidden;

    &::after {
      z-index: -1;
    }

    &.vjs-fluid {
      left: 50%;
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      @media screen and (min-width: 1441px) {
        height: 100% !important;
        padding-top: 0 !important;
      }
    }
  }

  video::after {
    background-color: rgba($black, 0.3);
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.player-main {
  .vjs-text-track-display {
    bottom: 5em;

    @media screen and (min-width: 768px) {
      bottom: 4em;
    }
  }
}

.hero--web {
  .vjs-text-track-display {
    bottom: 0 !important;
    height: 74%;

    @media screen and (min-width: 768px) {
      height: 85%;
    }

    @media screen and (min-width: 1500px) {
      height: 74%;
    }
  }

  .movie--ending .vjs-poster {
    display: block !important;
  }
}

.modal-border__header {
  .vjs-text-track-display {
    bottom: 5em !important;
  }

  .movie--ending .vjs-poster {
    display: block !important;
  }
}

.player-page {
  .video-js {
    /**
     * In devices where its aspect ratio is not 16/9,
     * the calculation of the height according to its width may not be adequate for the screen,
     * to avoid that the video overflows in height we add the following css rule.
     */
    padding-top: 100vh !important;
  
    @media screen and (device-aspect-ratio: 16/9) {
      padding-top: 56.25% !important;
    }
  }
}
