.header {
  @include font-family('montserrat-medium');

  padding: 0.5rem 4%;

  @media screen and (min-width: 1500px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  &__logo {
    width: 190px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  &__toggler {
    &:hover,
    &:active,
    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;
  width: 100%;
  -webkit-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  transition: background-color 0.5s;

  &--open {
    background-color: $black;
  }

  @media screen and (min-width: 768px) {
    position: relative;
  }

  a:hover {
    text-decoration: none;
  }
}

.download-link-list {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;

  @media screen and (min-width: 768px) {
    li {
      margin: 0 0.5rem;
    }
  }
}

.dropdown-option {
  &--logout {
    .dropdown-toggle {
      height: 26px;

      &::after {
        margin: 0 0.45em;
      }
    }

    .dropdown-option--toggle {
      padding: 7px 0;
      display: flex;
    }
  }

  &--language {
    .dropdown-toggle {
      color: $white;
      border: 1px solid $light;

      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  &--language.show,
  &--logout.show {
    .dropdown-toggle {
      background: inherit !important;
    }
  }

  &--logout.show {
    .dropdown-toggle::after {
      border-top: 0;
      border-bottom: 0.3em solid;
    }
  }

  &--logout,
  &--language {
    .dropdown-item {
      font-size: 14px;

      &:active,
      &:focus,
      &:hover {
        background-color: $color_gray;
        color: $white;
        outline: 0;
      }
    }
  }
}

.footer {
  position: relative;
  z-index: 10;

  &__gb-img {
    width: 110px;
  }

  &__link {
    @include font-family('montserrat-semibold');

    color: $color_primary_active;
    padding-top: 0.5rem;
    font-weight: bold;

    @media screen and (min-width: 768px) {
      padding-top: 0;
    }
  }

  &__detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    &__gout {
      float: left;
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &__info {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-top: 1px solid $white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
}
